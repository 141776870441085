@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Bungee+Shade&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: white;
  --secondary-color: #000000;
  --accent-color: #ff9623;
}

body {
  margin: 0;
  height: 100%;
  background-color: var(--primary-color);
  color: var(--secondary-color);

  font-family: 'Montserrat', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 200;
  animation: zoomInAnimation 2s ease;
}

h1 {
  font-family: 'Bungee Shade', 'Montserrat', 'Helvetica Neue', -apple-system, sans-serif;
  font-weight: normal;
}

h6,
h5,
h4,
h3,
h2 {
  font-family: 'Bungee', 'Montserrat', 'Helvetica Neue', -apple-system, sans-serif;
  font-weight: normal;
}

b,
strong {
  font-family: 'Bungee', 'Montserrat', 'Helvetica Neue', -apple-system, sans-serif;
}

a {
  color: var(--secondary-color);
  transition: color 1s ease;
}

a:hover {
  color: var(--accent-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
}

div :focus {
  color: var(--accent-color);
}

section {
  margin: 100px 0;
}

footer {
  margin-bottom: 100px;
}

@keyframes zoomInAnimation {
  0% {
    filter: blur(2rem) brightness(500%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(0.1);
  }
  80% {
    filter: blur(0.5rem);
  }
  100% {
    filter: unset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(1);
  }
}

@media screen and (min-width: 600px) {
  header {
    margin: 150px 0 0 0;
  }
  section {
    margin: 150px 0;
  }
  footer {
    margin-bottom: 150px;
  }
}
