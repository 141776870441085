.container {
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.skill {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 16px;
  margin: 8px;
  /*outline: 1px solid red;*/
  font-weight: bold;
}

.skill img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (min-width: 600px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 32px;
  }

  .skills {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 32px;
  }
}
