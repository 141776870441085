.container {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.4s ease;
  background: var(--secondary-color);
  color: var(--primary-color);
  padding: 8px 16px;
  border: none;
  border-radius: 16px;
}

.container:hover {
  background: var(--accent-color);
  color: var(--secondary-color);
}
