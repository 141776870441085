.universityContent {
  display: grid;
  gap: 32px;
}

.universityInnerContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.universityInnerAdditionalContent {
  display: grid;
  gap: 16px;
}

.award {
  display: grid;
  grid-template-columns: min-content max-content min-content;
  gap: 8px;
  align-items: center;
}

.course {
  display: grid;
  grid-template-columns: min-content max-content;
  gap: 8px;
  align-items: center;
}

.course > * {
  /*outline: 1px solid red;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .universityContent {
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 32px;
  }
}
