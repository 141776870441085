.container {
  visibility: hidden;
}

.visible {
  visibility: visible;
  animation: fadeInAnimation 0.4s ease forwards;
}

.title {
  position: relative;
  margin-bottom: 32px;
  transition: all 1s ease;
}

.title::before {
  content: attr(data-title);
  font-size: x-large;
  font-family: 'Bungee Shade', 'Montserrat', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  transition: all 1s ease;
}

@media screen and (min-width: 1000px) {
  .title {
    position: sticky;
    top: 20px;
    max-width: 0;
    margin-bottom: 60px;
  }

  .title::before {
    position: absolute;
    top: 0;
    right: 40px;
    display: inline-block;
    text-align: right;
  }
}

@keyframes fadeInAnimation {
  0% {
    transform: translateX(10%);
    filter: blur(0.3rem);
  }
  100% {
    transform: translateX(0%);
    filter: blur(0rem);
  }
}
