.container {
  display: grid;
  gap: 8px;
}
.titleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firstname {
  font-size: 6rem;
  animation: fadeInSlideLeftAnimation ease 1s;
  position: sticky;
}

.lastname {
  margin-top: -12px;
  font-size: 3rem;
}

.subContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subInnerContent {
  position: relative;
  display: flex;
  justify-content: center;
}

.leftSubContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 0;
  animation: fadeInPopLeftAnimation 0.8s ease 3s forwards;
}

.rightSubContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 0;
  animation: fadeInPopRightAnimation 0.8s ease 3s forwards;
}

.subtitle {
  text-transform: uppercase;
  padding: 8px 32px;
  background: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 20px;
  font-weight: bold;
  font-family: 'Bungee', 'Montserrat', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  position: relative;
  z-index: 100;
}

@keyframes fadeInSlideLeftAnimation {
  0% {
    margin-left: 40px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes fadeInPopLeftAnimation {
  0% {
    left: 100px;
  }
  100% {
    left: -75px;
  }
}

@keyframes fadeInPopRightAnimation {
  0% {
    right: 100px;
  }
  100% {
    right: -75px;
  }
}
