.innerContainer {
  display: grid;
  gap: 16px;
}

.project {
  display: grid;
  gap: 8px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
}
